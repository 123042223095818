.wrapper {
    display: flex;
    align-items: center;
    border-radius: 32px;
    background-color: #fefaf6;
    padding: 2px 16px;
    font-size: 14px;
    border: 1px solid #e2e2e2;

}

.name {
    font-weight: bold;
    font-size: 14px;
    margin-left: 12px;
}

.clap {
    font-weight: bold;
}

@media (max-width: 860px) {
    .name{display: none;}
}
.wrapper {
    background-color: white;
    border: 1px solid #318600;
    border-radius: 50%;
    width: 22px;
    min-width: 22px;
    height: 22px;
    min-height: 22px;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: -2px;
    font-size: 11px;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
}
.wrapper {
    padding: 18px 12px;
    background-color: #e3ffe3;
    // border-radius: 24px;
}

.inner {
    display: flex;
    flex-wrap: wrap;
}

.label {
    font-weight: bold;
    margin-right: 12px;
}

.filterElement {
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-top: 6px;
    margin-bottom: 6px
}

.export {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 1140px) {
    .filterElement {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .label {
        margin-bottom: 8px;
    }
}

@media (max-width: 870px) {
    .inner {
        flex-wrap: wrap;
    }
}